/**
 * Hilfsfunktion für Select2, damit dieses die korrekte Breite hat und deutsch ist.
 */
export function select2ify(el, userOptions) {
	const options = {
		//Select2 auf Deutsch umstellen:
		language: "de",
		//Resposive design
		width: "resolve",
	};

	$.extend(true, options, userOptions);

	return el.select2(options);
}
